import { Component, inject, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
	selector: 'sl-unauthenticated-layer',
	templateUrl: './unauthenticated-layer.component.html',
	styleUrl: './unauthenticated-layer.component.scss',
	standalone: true,
	imports: [RouterOutlet]
})
export class UnauthenticatedLayerComponent implements OnInit {
	readonly #renderer: Renderer2 = inject(RendererFactory2).createRenderer(null, null);

	constructor() {
		localStorage.removeItem('integrations');
	}

	ngOnInit(): void {
		this.#renderer.addClass(document.body, 'unauthenticated-page');
	}
}
